<template>
  <div class="orderitem" @click="onViewOrder">
    <span class="time">{{ helpers.formatDateTime(data.LastCreated) }}</span>

    <span v-if="!hidestatus" class="status">
      {{ $t.getTranslation(orderStatus[data.OrderStatusCode]) || data?.OrderStatusName }}
    </span>

    <h1>{{ data.OrderNumber }}</h1>

    <div class="info">
      <h4>{{ $t.getTranslation("LBL_TOTAL_QUANTITY") }}:</h4>
      <strong>{{ data.TotalQtyOrdered }} {{ $t.getTranslation("LBL_PCS") }}</strong>
    </div>

    <div class="info">
      <h4>{{ $t.getTranslation("LBL_GRAND_TOTAL") }}:</h4>
      <strong>{{ helpers.formatPrice(data.GrandTotal, data.CurrencyCode) }}</strong>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref } from "vue";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

export default defineComponent({
  name: "OrderItemCardComponent",
  components: {},
  props: { chatsend: Function, back: { type: Boolean, default: false }, data: Object, hidestatus: { type: Boolean, default: false } },
  setup(props) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;

    const orderStatus = ref({
      INITIATED: "LBL_ORDER_INITIATED",
      CONFIRMED: "LBL_ORDER_CONFIRMED",
      CLOSED: "LBL_ORDER_CLOSED",
      PAID: "LBL_ORDER_PAID",
      UNPAID: "LBL_ORDER_CLOSED",
      CANCELLED: "LBL_ORDER_CLOSED",
      SHIPPED: "LBL_ORDER_SHIPPED",
      PARTIALLY_PAID: "LBL_PARTIALLY_PAID",
    });

    const onViewOrder = () => {
      if (props?.chatsend) {
        props.chatsend(props.data.OrderKey);
        return;
      }

      if (props.back) {
        return $f7router.navigate({ name: "orderViewPage", query: { back: 1 }, params: { code: props.data.OrderKey } });
      }

      if (props.data?.SubOrderCounter > 0) {
        return $f7router.navigate({ name: "profileSubOrderPage", query: { ParentOrderKey: props.data.OrderKey } });
      }

      $f7router.navigate({ name: "orderViewPage", params: { code: props.data.OrderKey } });
    };

    return { helpers, onViewOrder, orderStatus, store };
  },
});
</script>
